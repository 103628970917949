import { scaleMobile } from "../../scale";

function bannerInlineStyleBodyDottedGroupItem({
  design: {
    fontSize
  },
  bodyDesign: {
    dottedGroupsInheritFontSize,
    dottedGroupsFontSize
  },
  mobile,
  pageRequestUuid4
}) {
  return {
    className: undefined,
    style: {
      paddingRight: 10,
      fontSize: scaleMobile(mobile, pageRequestUuid4, dottedGroupsInheritFontSize ? +fontSize : +dottedGroupsFontSize),
      whiteSpace: "nowrap",
      display: "inline-block"
    }
  };
}

export { bannerInlineStyleBodyDottedGroupItem };