/**
 * Port of `preg_quote`.
 *
 * @see https://github.com/kvz/locutus/blob/3f14dc5d142f5dcbdf36b4271c21a850a4a259da/src/php/pcre/preg_quote.js#L1
 */
function pregQuote(str, delimiter) {
  //  discuss at: https://locutus.io/php/preg_quote/
  // original by: booeyOH
  // improved by: Ates Goral (https://magnetiq.com)
  // improved by: Kevin van Zonneveld (https://kvz.io)
  // improved by: Brett Zamir (https://brett-zamir.me)
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  //   example 1: preg_quote("$40")
  //   returns 1: '\\$40'
  //   example 2: preg_quote("*RRRING* Hello?")
  //   returns 2: '\\*RRRING\\* Hello\\?'
  //   example 3: preg_quote("\\.+*?[^]$(){}=!<>|:")
  //   returns 3: '\\\\\\.\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:'
  return str.replace(new RegExp("[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\".concat(delimiter || "", "-]"), "g"), "\\$&");
}

export { pregQuote };