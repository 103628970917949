import _extends from "@babel/runtime/helpers/extends";
import { useCallback, Fragment } from "react";
import { useBannerOrBlocker, useLegalLinks } from "..";
import { FooterLink, FooterPoweredByLink } from "../../components";
import { jsxJoin } from "../../utils";

function useCommonFooterLinks({
  onClose,
  putPoweredByLinkInRow,
  row1
} = {}) {
  const {
    i18n: {
      close
    },
    footerDesign: {
      poweredByLink,
      linkTarget
    },
    poweredLink,
    affiliate
  } = useBannerOrBlocker();
  const {
    linkPrivacyPolicy,
    linkImprint
  } = useLegalLinks();
  const targetAttributes = linkTarget === "_blank" ? {
    target: "_blank",
    rel: "noopener"
  } : {};
  const rows = [// Row 1
  [onClose && h(FooterLink, {
    href: "#",
    onClick: onClose,
    key: "close",
    "interaction-player-skip": "1"
  }, h("strong", null, close)), ...(row1 || []), linkPrivacyPolicy && h(FooterLink, _extends({
    href: linkPrivacyPolicy.url
  }, targetAttributes, {
    key: "privacyPolicy"
  }), linkPrivacyPolicy.label), linkImprint && h(FooterLink, _extends({
    href: linkImprint.url
  }, targetAttributes, {
    key: "imprint"
  }), linkImprint.label)].filter(Boolean), // Row 2
  [] // Nothing yet
  ];

  if (!!poweredLink && poweredByLink) {
    rows[putPoweredByLinkInRow || 0].push(h(FooterPoweredByLink, {
      key: "poweredBy",
      poweredLink: poweredLink,
      affiliate: affiliate
    }));
  }

  const render = useCallback(rows => jsxJoin(rows.map(row => row.length ? h(Fragment, {
    key: row[0].key
  }, jsxJoin(row, h(Fragment, null, " \u2022 "))) : null), h("br", null)), []);
  return {
    rows,
    render
  };
}

export { useCommonFooterLinks };