import { scaleMobile } from "../../scale";
const BUTTONS_WIDTH = 300;

function bannerInlineStylesLeftSide({
  layout: {
    type
  },
  bodyDesign: {
    padding
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  individualPrivacyOpen,
  activeAction,
  pageRequestUuid4
}) {
  const useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  const usePadding = scaleMobile(mobile, pageRequestUuid4, padding);
  const style = {
    float: useType === "banner" && activeAction !== "history" ? "left" : undefined,
    width: useType === "banner" && activeAction !== "history" ? "calc(100% - ".concat(BUTTONS_WIDTH, "px)") : undefined,
    paddingRight: useType === "banner" ? 20 : undefined,
    paddingTop: +usePadding[0],
    paddingBottom: useType === "banner" || individualPrivacyOpen ? +usePadding[2] : 0
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tb-left",
    style
  };
}

export { BUTTONS_WIDTH, bannerInlineStylesLeftSide };