import { scaleMobile } from "../..";

function bannerGroupsInlineStyleGroup({
  group: {
    groupBg,
    groupBorderRadius,
    groupBorderColor,
    groupPadding,
    groupBorderWidth,
    groupInheritBg
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  const useGroupBorderWidth = scaleMobile(mobile, pageRequestUuid4, groupBorderWidth);
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group",
    style: {
      background: groupInheritBg ? undefined : groupBg,
      border: useGroupBorderWidth > 0 ? "".concat(useGroupBorderWidth, "px solid ").concat(groupBorderColor) : undefined,
      padding: scaleMobile(mobile, pageRequestUuid4, groupPadding).map(p => "".concat(p, "px")).join(" "),
      borderRadius: +groupBorderRadius,
      textAlign: "left"
    }
  };
}

export { bannerGroupsInlineStyleGroup };