import { useCallback, useMemo } from "react";
import { BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "../..";
/**
 * Depending on the current screen, calculate the animation to use.
 *
 * Currently, we do not support animations on resize as we cannot calculate the
 * width of the overlay container as it does not exist on first render cycle of React.
 *
 * Drawback if animation only on mobile is enabled: if the window is first a mobile,
 * and the window gets resize it will reuse the animation on desktop again
 */

function useBannerAnimation() {
  const clientWidth = useMemo(() => window.innerWidth, []);
  const isMobile = clientWidth < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER; // Check if it is mobile Firefox, as it does not support `position:sticky;bottom:0;` and animations.
  // Effect: The buttons and footer are not visible.

  const isMobileFirefox = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return ["firefox", "gecko", "mobile", "android"].map(term => userAgent.indexOf(term) > -1).filter(Boolean).length === 4;
  }, []);
  return useCallback(({
    animationIn,
    animationInOnlyMobile,
    animationOut,
    animationOutOnlyMobile
  }) => {
    let useAnimationIn = animationInOnlyMobile ? isMobile ? animationIn : "none" : animationIn;
    let useAnimationOut = animationOutOnlyMobile ? isMobile ? animationOut : "none" : animationOut;

    if (isMobileFirefox) {
      useAnimationIn = "none";
      useAnimationOut = "none";
    }

    return {
      useAnimationIn,
      useAnimationOut
    };
  }, []);
}

export { useBannerAnimation };