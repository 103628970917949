import { calculateRgba } from "../../../utils";
import { scaleMobile } from "../../scale";

function bannerContentInlineStyleInner({
  layout: {
    type,
    dialogMaxWidth,
    dialogPosition,
    dialogMargin,
    bannerPosition,
    dialogBorderRadius
  },
  design: {
    borderWidth,
    borderColor,
    textAlign,
    fontColor,
    fontInheritFamily,
    fontFamily,
    fontWeight,
    boxShadowEnabled,
    boxShadowOffsetX,
    boxShadowOffsetY,
    boxShadowBlurRadius,
    boxShadowSpreadRadius,
    boxShadowColor,
    boxShadowColorAlpha
  },
  individualLayout,
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4,
  individualPrivacyOpen
}) {
  const {
    alignment: mobileAlignment
  } = mobile;
  const useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, borderWidth);
  const width = useType === "dialog" ? +(individualPrivacyOpen && !individualLayout.inheritDialogMaxWidth ? individualLayout.dialogMaxWidth : dialogMaxWidth) : "100%";
  const style = {
    pointerEvents: "all",
    transition: "width 500ms, max-width 500ms",
    maxWidth: width,
    textAlign,
    width,
    overflow: "hidden",
    alignSelf: scaleMobile(mobile, pageRequestUuid4, useType === "banner" ? bannerPosition === "top" ? "flex-start" : "flex-end" : undefined, mobileAlignment === "bottom" ? "flex-end" : mobileAlignment === "top" ? "flex-start" : "center"),
    fontFamily: fontInheritFamily ? undefined : fontFamily,
    fontWeight,
    color: fontColor,
    margin: useType === "dialog" ? 10 : undefined,
    borderRadius: useType === "dialog" ? +dialogBorderRadius : undefined,
    boxShadow: boxShadowEnabled ? "".concat(boxShadowOffsetX, "px ").concat(boxShadowOffsetY, "px ").concat(boxShadowBlurRadius, "px ").concat(boxShadowSpreadRadius, "px ").concat(calculateRgba(boxShadowColor, boxShadowColorAlpha)) : undefined,
    [useType === "banner" ? bannerPosition === "top" ? "borderBottom" : "borderTop" : "border"]: useType === "banner" ? useBorderWidth > 0 ? "".concat(useBorderWidth, "px solid ").concat(borderColor) : undefined : undefined
  };

  if (useType === "dialog" && dialogPosition !== "middleCenter") {
    style.margin = dialogMargin.map(i => "".concat(i, "px")).join(" ");
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-inner",
    style
  };
}

export { bannerContentInlineStyleInner };