function bannerInlineStyleBodyDottedGroupBullet({
  bodyDesign: {
    dottedGroupsBulletColor
  }
}) {
  return {
    className: undefined,
    style: {
      color: dottedGroupsBulletColor
    }
  };
}

export { bannerInlineStyleBodyDottedGroupBullet };