import { scaleMobile } from "../../scale";

function bannerFooterInlineStyleSeparator({
  layout: {
    type
  },
  design,
  footerDesign: {
    borderWidth,
    borderColor
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  const useBorderWidth = scaleMobile(mobile, pageRequestUuid4, design.borderWidth);
  const style = {
    height: scaleMobile(mobile, pageRequestUuid4, +borderWidth),
    background: borderColor,
    position: "sticky",
    bottom: "var(--rendered-height-footer)"
  };

  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog" && useBorderWidth > 0) {
    style.borderLeft = "".concat(useBorderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-separator",
    style
  };
}

export { bannerFooterInlineStyleSeparator };