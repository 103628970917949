import { useBanner, bannerGroupsInlineStyleTitle, bannerGroupsInlineStyleDescription } from "../../..";

const BannerGroupSectionTitle = ({
  title,
  children
}) => {
  const banner = useBanner();
  return h("div", bannerGroupsInlineStyleTitle(banner), title, children && h("div", bannerGroupsInlineStyleDescription(banner), children));
};

export { BannerGroupSectionTitle };