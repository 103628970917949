import { useMemo } from "react";
import { useState } from "react";
import { getOptionsFromWindow, getOtherOptionsFromWindow } from "../utils";
import { useBannerPreDecisionGateway, useTcf } from "../hooks";
import { applyConsent, getCookieConsentManager } from "../others";
import { prepareTcfString } from "../tcf";
import { getUserDecision } from "@devowl-wp/cookie-consent-web-client";
import { useBannerConsentListener } from "@devowl-wp/react-cookie-banner";
import { BannerContextFactory, useBannerToggleOverlay, Banner, useBannerStateContextCallbacks } from "@devowl-wp/react-cookie-banner";
import { locationRestConsentGet } from "../wp-api";
import { commonRequest } from "@devowl-wp/utils";
import { BannerProductionNotice } from ".";
import { Recorder } from "@devowl-wp/web-html-element-interaction-recorder";
/**
 * Simply connects a context store to the banner itself. On the website itself
 * it should not rely
 */

const WebsiteBanner = ({
  poweredLink
}) => {
  const {
    customizeValuesBanner: {
      layout,
      decision,
      legal,
      design,
      headerDesign,
      bodyDesign,
      footerDesign,
      texts,
      individualLayout,
      saveButton,
      group,
      individualTexts,
      customCss,
      mobile
    },
    pageId,
    pageRequestUuid4,
    pageIdToPermalink,
    consentForwardingExternalHosts,
    essentialGroup,
    isTcf,
    isEPrivacyUSA,
    isAgeNotice,
    isListServicesNotice,
    tcf,
    tcfMetadata,
    groups,
    userConsentCookieName,
    bannerDesignVersion,
    bannerI18n,
    isPro,
    isLicensed,
    isDevLicense,
    affiliate,
    isCurrentlyInTranslationEditorPreview,
    pageByIdUrl
  } = getOtherOptionsFromWindow();
  const userDecision = getUserDecision(userConsentCookieName); // TCF compatibility

  const tcfObject = useTcf(isTcf, tcf, tcfMetadata); // Server-side rendered overlay handler

  const overlay = document.getElementById(pageRequestUuid4);
  const recorder = useMemo(() => new Recorder(overlay), []);
  const {
    toggleOverlay
  } = useBannerToggleOverlay(overlay);
  const {
    openHistory,
    openBanner,
    revokeConsent,
    updateTcfFilterBy,
    updateIndividualPrivacyOpen,
    updateGroupChecked,
    updateCookieChecked,
    onClose,
    selectHistoryEntry
  } = useBannerStateContextCallbacks({
    toggleOverlay,
    revokeConsent: ({
      tcf
    }) => {
      // Do not rely on current state of `groups` because it can be altered through history change
      const buttonClicked = "shortcode_revoke"; // TCF compatibility: revoke TCF string

      let tcfString;

      if (process.env.IS_TCF === "1" && tcf !== null && tcf !== void 0 && tcf.model) {
        tcfString = prepareTcfString(tcf.model, buttonClicked);
      }

      return applyConsent({
        consent: {
          groups: getCookieConsentManager().getDefaultDecision(true)
        },
        buttonClicked,
        tcfString
      });
    }
  }); // Context values preparation

  const [contextValue, setContextValue] = useState({
    recorder,
    layout: { ...layout
    },
    decision: { ...decision
    },
    legal: { ...legal
    },
    design: { ...design
    },
    headerDesign: { ...headerDesign
    },
    bodyDesign: { ...bodyDesign
    },
    footerDesign: { ...footerDesign
    },
    texts: { ...texts
    },
    individualLayout: { ...individualLayout
    },
    saveButton: { ...saveButton
    },
    group: { ...group
    },
    individualTexts: { ...individualTexts
    },
    customCss: { ...customCss
    },
    mobile: { ...mobile
    },
    productionNotice: h(BannerProductionNotice, {
      isPro: isPro,
      isLicensed: isLicensed,
      isDevLicense: isDevLicense,
      i18n: bannerI18n
    }),
    pageRequestUuid4,
    pageIdToPermalink,
    pageByIdUrl,
    consentForwardingExternalHosts,
    pageId,
    isTcf,
    ePrivacyUSA: isEPrivacyUSA,
    ageNotice: isAgeNotice,
    listServicesNotice: isListServicesNotice,
    tcf: tcfObject,
    tcfFilterBy: "legInt",
    groups,
    essentialGroup,
    poweredLink,
    visible: false,
    animationVisible: true,
    skipOverlay: true,
    previewCheckboxActiveState: false,
    individualPrivacyOpen: false,
    designVersion: bannerDesignVersion,
    i18n: bannerI18n,
    keepVariablesInTexts: isCurrentlyInTranslationEditorPreview,
    affiliate,
    consent: {
      groups: { ...(userDecision === false ? {} : userDecision.consent),
        // Always override essential cookies
        ...getCookieConsentManager().getDefaultDecision(userDecision === false)
      }
    },
    didGroupFirstChange: false,
    onSave: (markAsDoNotTrack, buttonClicked) => {
      setContextValue(state => {
        var _state$tcf;

        // TCF compatibility: persist TCF string
        let tcfString;

        if (process.env.IS_TCF === "1" && isTcf && (_state$tcf = state.tcf) !== null && _state$tcf !== void 0 && _state$tcf.model) {
          tcfString = prepareTcfString(state.tcf.model, buttonClicked);
        }

        const appliedConsent = applyConsent({
          consent: state.consent,
          markAsDoNotTrack,
          buttonClicked,
          tcfString,
          recorderJsonString: JSON.stringify(recorder.createReplay()),
          uiView: state.activeAction === "change" ? "change" : state.activeAction !== "revoke" ? "initial" : undefined
        });

        if (state.refreshSiteAfterSave) {
          appliedConsent.then(() => // Fix iOS / Safari bug when we want to reload the page after a POST request
          setTimeout(() => window.location.reload(), state.refreshSiteAfterSave ||
          /* BC */
          2000)); // When refreshing the site, do not show any out animation

          return state;
        } else {
          return { ...state,
            ...toggleOverlay(state, false)
          };
        }
      });
    },
    fetchHistory: async () => {
      const {
        restNamespace,
        restRoot,
        restQuery,
        restNonce
      } = getOptionsFromWindow();
      const history = await commonRequest({
        location: locationRestConsentGet,
        options: {
          restNamespace,
          restRoot,
          restQuery,
          restNonce
        },
        cookieValueAsParam: [userConsentCookieName],
        sendRestNonce: false
      });
      setContextValue(state => {
        return { ...state,
          history
        };
      });
      return history;
    },
    // Predefined callbacks
    updateTcfFilterBy: newFilter => updateTcfFilterBy(setContextValue, newFilter),
    updateIndividualPrivacyOpen: opened => updateIndividualPrivacyOpen(setContextValue, opened),
    updateGroupChecked: (id, checked) => updateGroupChecked(setContextValue, id, checked),
    updateCookieChecked: (groupId, id, checked) => updateCookieChecked(setContextValue, groupId, id, checked),
    onClose: () => onClose(setContextValue),
    selectHistoryEntry: (groups, consentGroups, tcf) => selectHistoryEntry(setContextValue, groups, consentGroups, tcf),
    openHistory: e => openHistory(setContextValue, e),
    openBanner: e => openBanner(setContextValue, e),
    revokeConsent: (successMessage, e) => revokeConsent(setContextValue, successMessage, e)
  });
  useBannerPreDecisionGateway(contextValue, setContextValue, toggleOverlay);
  useBannerConsentListener(setContextValue, userConsentCookieName);
  const BannerContext = BannerContextFactory.Context();
  return h(BannerContext.Provider, {
    value: contextValue
  }, h(Banner, null));
};

export { WebsiteBanner };