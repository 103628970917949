import { createTcfModel } from "@devowl-wp/cookie-consent-web-client";

const cancelEvent = e => {
  if (!e) {
    return;
  }

  e.preventDefault(); // Some themes (e.g. AKEA) do have listeners on each link separately and do a custom redirect

  e.stopPropagation();
};

function useBannerStateContextCallbacks({
  toggleOverlay,
  revokeConsent
}) {
  const toggleWithActiveAction = (setContextValue, type) => setContextValue(state => {
    return { ...state,
      activeAction: type,
      individualPrivacyOpen: true,
      refreshSiteAfterSave: type === "change" ? 2000 : false,
      ...toggleOverlay(state, true)
    };
  });

  return {
    openHistory: (setContextValue, ...args) => {
      toggleWithActiveAction(setContextValue, "history");
      cancelEvent(args[0]);
    },
    openBanner: (setContextValue, ...args) => {
      toggleWithActiveAction(setContextValue, "change");
      cancelEvent(args[0]);
    },
    revokeConsent: (setContextValue, ...args) => {
      const [successMessage, e] = args;
      setContextValue(state => {
        revokeConsent(state).then(() => {
          successMessage && alert(successMessage); // Always reload (always wait 2s to ensure opt-out scripts are exeucted)
          // Fix iOS / Safari bug when we want to reload the page after a POST request

          setTimeout(() => window.location.reload(), 2000);
        }); // Do not change anything

        return state;
      });
      cancelEvent(e);
    },
    updateTcfFilterBy: (setContextValue, ...args) => {
      const [newFilter] = args;
      setContextValue(state => ({ ...state,
        tcfFilterBy: newFilter
      }));
    },
    updateIndividualPrivacyOpen: (setContextValue, ...args) => {
      const [opened] = args;
      setContextValue(state => ({ ...state,
        individualPrivacyOpen: opened
      }));
    },
    updateGroupChecked: (setContextValue, ...args) => {
      const [id, checked] = args;
      setContextValue(state => {
        const newConsent = JSON.parse(JSON.stringify(state.consent));

        if (checked) {
          newConsent.groups[id] = state.groups.filter(group => group.id === id)[0].items.map(({
            id
          }) => id);
        } else {
          delete newConsent.groups[id];
        }

        return { ...state,
          consent: newConsent,
          didGroupFirstChange: true
        };
      });
    },
    updateCookieChecked: (setContextValue, ...args) => {
      const [groupId, id, checked] = args;
      setContextValue(state => {
        const newConsent = JSON.parse(JSON.stringify(state.consent)); // Initially create "selected" cookies

        if (!newConsent.groups[groupId]) {
          newConsent.groups[groupId] = [];
        }

        const cookieIds = newConsent.groups[groupId];
        const indexOf = cookieIds.indexOf(id);

        if (checked && indexOf === -1) {
          cookieIds.push(id);
        } else if (!checked && indexOf > -1) {
          cookieIds.splice(indexOf, 1);
        } // Delete group entirely when no cookies selected


        if (!cookieIds.length) {
          delete newConsent.groups[groupId];
        }

        return { ...state,
          consent: newConsent
        };
      });
    },
    onClose: setContextValue => {
      setContextValue(state => {
        return { ...state,
          ...toggleOverlay(state, false),
          // This should be set always by you again as it is one-time
          refreshSiteAfterSave: false
        };
      });
    },
    selectHistoryEntry: (setContextValue, ...args) => {
      const [groups, consentGroups, tcf] = args;
      setContextValue(state => {
        const additionalChanges = {
          // In case we previously used non-TCF functionality, do not show the TCF banner
          isTcf: !!tcf,
          tcf: null
        };

        if (process.env.IS_TCF === "1" && tcf) {
          Object.assign(additionalChanges, {
            tcf: "gvl" in tcf ? tcf : createTcfModel(tcf)
          });
        }

        return { ...state,
          groups,
          consent: { ...state.consent,
            groups: consentGroups
          },
          ...additionalChanges
        };
      });
    }
  };
}

export { useBannerStateContextCallbacks };