function bannerInlineStyleBodyDottedGroups({
  customCss: {
    antiAdBlocker
  }
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-dotted-groups",
    style: {
      marginTop: 10,
      lineBreak: "anywhere",
      lineHeight: 2
    }
  };
}

export { bannerInlineStyleBodyDottedGroups };