import { useMemo, useLayoutEffect } from "react";
let counter = 0;
/**
 * Dynamically creates a style-tag in your head with a given plain CSS string.
 */

function usePlainCss(css, uniqueId, keep) {
  const id = useMemo(() => "react-use-plain-css-".concat(uniqueId || (counter++).toString(36)), []);
  useLayoutEffect(() => {
    let element = document.getElementById(id);

    if (!element) {
      element = document.createElement("style");
      element.style.type = "text/css";
      element.id = id;
      document.getElementsByTagName("head")[0].appendChild(element);
    }

    element.innerHTML = css;
    return () => {
      if (!keep) {
        var _element$parentNode;

        (_element$parentNode = element.parentNode) === null || _element$parentNode === void 0 ? void 0 : _element$parentNode.removeChild(element);
      }
    };
  }, [css, keep]);
  return id;
}

export { usePlainCss };