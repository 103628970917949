import { hexToRgb } from ".";

/**
 * @param hex
 * @param alpha 0 - 100
 */
function calculateRgba(hex, alpha) {
  const {
    r,
    g,
    b
  } = hexToRgb(hex);
  return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha / 100, ")");
}

export { calculateRgba };