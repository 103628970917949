import { useBanner, useBannerButtonCallbacks, scaleMobile, CloseIcon, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "../../..";

const BannerCloseIcon = () => {
  const {
    headerDesign: {
      fontSize,
      fontColor
    },
    texts: {
      acceptEssentials
    },
    mobile,
    activeAction,
    pageRequestUuid4,
    i18n: {
      close,
      closeWithoutSaving
    },
    buttonClicked = ""
  } = useBanner();
  const {
    buttonClickedCloseIcon,
    closeIcon
  } = useBannerButtonCallbacks();
  const container = document.getElementById(pageRequestUuid4);
  return h(CloseIcon, {
    width: scaleMobile(mobile, pageRequestUuid4, fontSize),
    color: fontColor,
    tooltipText: activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials,
    tooltipAlways: (container === null || container === void 0 ? void 0 : container.clientWidth) < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER,
    framed: buttonClicked === buttonClickedCloseIcon,
    renderInContainer: container,
    onClick: closeIcon
  });
};

export { BannerCloseIcon };