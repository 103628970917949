export const LABEL_GTM = "Google Tag Manager";
export const LABEL_MTM = "Matomo Tag Manager";

function getManagerDependingVariables(setCookiesViaManager, presetId) {
  let useGlobalObjectName;
  let getDataLayer;
  let serviceIsManager = false;
  let managerLabel = "";
  let expectedManagerPresetId;

  if (setCookiesViaManager && setCookiesViaManager !== "none") {
    const isGtm = setCookiesViaManager === "googleTagManager";
    const isMtm = setCookiesViaManager === "matomoTagManager";

    if (isGtm) {
      expectedManagerPresetId = "gtm";
      useGlobalObjectName = "dataLayer";
      managerLabel = LABEL_GTM;
    } else if (isMtm) {
      expectedManagerPresetId = "mtm";
      useGlobalObjectName = "_mtm";
      managerLabel = LABEL_MTM;
    }

    if (isGtm || isMtm) {
      getDataLayer = () => {
        window[useGlobalObjectName] = window[useGlobalObjectName] || [];
        return window[useGlobalObjectName];
      };
    }

    if (expectedManagerPresetId && presetId === expectedManagerPresetId) {
      serviceIsManager = true;
    }
  }

  return {
    getDataLayer,
    serviceIsManager,
    managerLabel,
    expectedManagerPresetId
  };
}

export { getManagerDependingVariables };