function commonGroupsInlineStyleLinkMore({
  design: {
    linkTextDecoration
  },
  group: {
    linkColor,
    linkHoverColor
  },
  customCss: {
    antiAdBlocker
  }
}, isHover) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group-more",
    style: {
      color: isHover ? linkHoverColor : linkColor,
      textDecoration: linkTextDecoration
    }
  };
}

export { commonGroupsInlineStyleLinkMore };