import { Fragment } from "react";

const BannerProductionNotice = ({
  isPro,
  isLicensed,
  isDevLicense,
  i18n: {
    noLicense,
    devLicense,
    devLicenseLearnMore,
    devLicenseLink
  }
}) => {
  if (!isPro) {
    return null;
  }

  const style = {
    fontSize: 13,
    color: "#c95252",
    fontWeight: "bold",
    order: 9
  };
  let useText = "";

  if (isDevLicense) {
    useText = devLicense;
  } else if (!isLicensed) {
    useText = noLicense;
  }

  return useText ? h("div", {
    style: style
  }, useText, isDevLicense && h(Fragment, null, " (", h("a", {
    href: devLicenseLink,
    rel: "noreferrer",
    target: "_blank",
    style: style
  }, devLicenseLearnMore), ")")) : null;
};

export { BannerProductionNotice };