import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useState } from "react";
import { commonGroupsInlineStyleLinkMore } from "../../inlineStyles";
import { useBannerOrBlocker } from "../../hooks";

const CommonLinkMore = ({
  onToggle,
  children,
  showMore,
  hideMore,
  ...rest
}) => {
  const bannerOrBlocker = useBannerOrBlocker();
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  return h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h("a", _extends({
    href: "#"
  }, commonGroupsInlineStyleLinkMore(bannerOrBlocker, isHover), {
    onClick: e => {
      const newOpen = !isOpen;
      setIsOpen(newOpen);
      onToggle === null || onToggle === void 0 ? void 0 : onToggle(newOpen);
      e.preventDefault();
    },
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false)
  }, rest), isOpen ? hideMore : showMore), isOpen && children);
};

export { CommonLinkMore };