import { apply } from ".";
import { getDefaultDecision, getUserDecision } from "..";

/**
 * Main class to manage cookie consents within your application.
 */
class CookieConsentManager {
  constructor(options) {
    this.options = void 0;
    this.options = options;
  }

  applyCookies(options) {
    return apply({ ...options,
      ...this.options
    });
  }

  getUserDecision() {
    return getUserDecision(this.getOption("decisionCookieName"));
  }

  getDefaultDecision(respectLegitimateInterests = true) {
    return getDefaultDecision(this.options, respectLegitimateInterests);
  }

  getOption(name) {
    return this.options[name];
  }

  getOptions() {
    return this.options;
  }

}

export { CookieConsentManager };