import _extends from "@babel/runtime/helpers/extends";
import { Fragment, useLayoutEffect, useState } from "react";
import { commonInlineStyleGroupsCheckbox } from "../../inlineStyles";
import { PlainAntdIconAsSvg } from "../..";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons-svg";
import { useBannerOrBlocker } from "../../hooks";

const CommonCheckbox = ({
  hideCheckbox,
  isPartial,
  isChecked,
  isDisabled,
  fontSize,
  onClick,
  children,
  after,
  ...rest
}) => {
  const CheckboxIcon = isPartial ? MinusOutlined : CheckOutlined;
  const bannerOrBlocker = useBannerOrBlocker();
  const [isRendered, setIsRendered] = useState(false);
  useLayoutEffect(() => {
    setIsRendered(true);
  }, []);
  return h(Fragment, null, !hideCheckbox && h(PlainAntdIconAsSvg, _extends({
    icon: CheckboxIcon,
    onClick: onClick
  }, commonInlineStyleGroupsCheckbox(bannerOrBlocker, isRendered, isChecked, isDisabled, fontSize), rest)), children && h("span", {
    style: {
      verticalAlign: "middle",
      cursor: "pointer"
    }
  }, h("span", {
    onClick: onClick
  }, children), after));
};

export { CommonCheckbox };