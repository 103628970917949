function bannerInlineStyleHistorySelectOption({
  design: {
    bg
  }
}) {
  return {
    className: undefined,
    style: {
      background: bg
    }
  };
}

export { bannerInlineStyleHistorySelectOption };