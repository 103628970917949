import { useState, useEffect } from "react";

function getAnimationRelatedState({
  isVisible,
  animationIn,
  animationOut,
  animationInDuration,
  animationOutDuration,
  animationInDelay,
  animationOutDelay
}) {
  return isVisible ? {
    animation: animationIn,
    duration: animationInDuration,
    delay: animationInDelay
  } : {
    animation: animationOut,
    duration: animationOutDuration,
    delay: animationOutDelay
  };
}
/**
 * Ported from https://www.npmjs.com/package/react-animated-css due to high bundle size.
 */


const AnimatedCss = ({
  animateOnMount = true,
  isVisible = true,
  animationIn = "fadeIn",
  animationOut = "fadeOut",
  animationInDelay = 0,
  animationOutDelay = 0,
  animationInDuration = 1000,
  animationOutDuration = 1000,
  className = "",
  style = {},
  children
}) => {
  const [{
    animation,
    duration,
    delay
  }, setAnimationRelatedState] = useState(animateOnMount ? getAnimationRelatedState({
    isVisible,
    animationIn,
    animationOut,
    animationInDelay,
    animationOutDelay,
    animationInDuration,
    animationOutDuration
  }) : {
    animation: "",
    delay: undefined,
    duration: 0
  });
  useEffect(() => {
    setAnimationRelatedState(getAnimationRelatedState({
      isVisible,
      animationIn,
      animationOut,
      animationInDelay,
      animationOutDelay,
      animationInDuration,
      animationOutDuration
    }));
  }, [isVisible, animationIn, animationOut, animationInDelay, animationOutDelay, animationInDuration, animationOutDuration]);
  const classes = "animate__animated animate__".concat(animation, " ").concat(className);
  const backwardStyle = !animation ? {
    opacity: isVisible ? 1 : 0,
    transition: "opacity ".concat(delay, "ms")
  } : {};
  return h("div", {
    className: classes,
    style: {
      animationDelay: "".concat(delay, "ms"),
      animationDuration: "".concat(duration, "ms"),
      pointerEvents: isVisible ? "all" : "none",
      ...style,
      ...backwardStyle
    }
  }, children);
};

export { AnimatedCss };