import { useState, useEffect } from "react";

const AsciiSpinner = ({
  ms = 100,
  chars = ["&#9719;", "&#9718;", "&#9717;", "&#9716;"]
}) => {
  const [i, setI] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setI(i + 1);
    }, ms);
    return () => clearInterval(interval);
  }, [i]);
  return h("span", {
    dangerouslySetInnerHTML: {
      __html: chars[i % 4]
    }
  });
};

export { AsciiSpinner };