import { scaleMobile } from "../../scale";

function bannerContentInlineStyle({
  layout: {
    type
  },
  customCss: {
    antiAdBlocker
  },
  decision: {
    showCloseIcon
  },
  activeAction,
  individualPrivacyOpen,
  mobile,
  pageRequestUuid4
}) {
  var _document$getElementB;

  const innerHeight = ((_document$getElementB = document.getElementById(pageRequestUuid4)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientHeight) || window.innerHeight;
  const mobileMaxHeight = +mobile.maxHeight;
  const useMobileMaxHeight = mobileMaxHeight > innerHeight ? innerHeight : mobileMaxHeight;
  const closeIconDistance = showCloseIcon || activeAction ? 51 : 0;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-content",
    style: {
      position: "relative",
      maxHeight: scaleMobile(mobile, pageRequestUuid4, innerHeight - (type === "banner" ? 0 : 20) - closeIconDistance, individualPrivacyOpen ? innerHeight - closeIconDistance : useMobileMaxHeight - closeIconDistance),
      overflow: "auto"
    }
  };
}

export { bannerContentInlineStyle };