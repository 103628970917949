import Cookie from "js-cookie";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";
import { regExpPatternFromWildcardName } from "../utils";
/**
 * Delete cookies on client side (currently only `localStorage` and `sessionStorage`).
 */

function deleteCookiesLocally(technicalDefinitions, dynamics) {
  for (const {
    type,
    name
  } of technicalDefinitions) {
    // Create valid Regxp pattern
    const pattern = new RegExp(regExpPatternFromWildcardName(applyDynamicsToHtml(name, dynamics)), "g");

    switch (type) {
      case "http":
        {
          for (const key of Object.keys(Cookie.get())) {
            if (pattern.test(key)) {
              Cookie.remove(key);
            }
          }

          break;
        }

      case "local":
      case "session":
        {
          try {
            const useStorage = type === "local" ? window.localStorage : window.sessionStorage;

            if (useStorage) {
              for (const itemName of Object.keys(useStorage)) {
                if (pattern.test(itemName)) {
                  try {
                    // Fallback to `null` because `removeItem` can have issues on some browsers
                    useStorage.setItem(itemName, null);
                  } catch (e) {// Silence is golden. A `QuotaExceededError` can be thrown but we ignore it, because we remove the item
                  } // Try as long (but max 100 times) until deleted


                  let tries = 0;

                  while (useStorage.getItem(itemName) && tries < 100) {
                    tries++;
                    useStorage.removeItem(itemName);
                  }
                }
              }
            }
          } catch (e) {
            // Browser does not support localStorage, let's do nothing...
            continue;
          }

          break;
        }

      default:
        break;
    }
  }
}

export { deleteCookiesLocally };