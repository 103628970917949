import { getUserDecision } from ".";
import { getManagerDependingVariables } from "../apply";

/**
 * Read the selected groups + services depending on the passed type.
 */
function getServiceSelection({
  decisionCookieName,
  tagManager,
  groups,
  essentialGroupSlug,
  type
}) {
  let selectedGroups;
  const isManagerActive = tagManager && tagManager !== "none";
  const [essentialGroupObj] = groups.filter(({
    slug
  }) => slug === essentialGroupSlug);
  const consentEssentials = {
    [essentialGroupObj.id]: essentialGroupObj.items.map(({
      id
    }) => id)
  }; // Read current consent

  if (type === "consent") {
    const decision = getUserDecision(decisionCookieName);

    if (decision !== false) {
      selectedGroups = decision.consent;
    } else {
      console.warn("Something went wrong while reading the cookie, fallback to essentials only...");
      selectedGroups = consentEssentials;
    }
  }

  if (type === "essentials") {
    selectedGroups = consentEssentials;
  }

  return {
    isManagerActive,
    selectedGroups,
    iterateServices: async function (callback) {
      for (const group of groups) {
        for (const service of group.items) {
          var _selectedGroups$group;

          const isOptIn = type === "all" || ((_selectedGroups$group = selectedGroups[group.id]) === null || _selectedGroups$group === void 0 ? void 0 : _selectedGroups$group.indexOf(service.id)) > -1;
          const useTagManager = getManagerDependingVariables(tagManager, service.presetId);
          await callback(group, service, isOptIn, useTagManager);
        }
      }
    }
  };
}

export { getServiceSelection };