import { getUserDecision } from "../decision";
import { isCrawler, promiseTimeout } from "../utils";

/**
 * Dynamically allow to get a predecision with a extra request to an external API.
 * If your server does not respond within a given timeout or with an error, it automatically
 * continues with the predecisions. Otherwise, the predecision of the response is used.
 */
const preDecisionGatewayDynamicRequest = (request, timeout = 10000, active = true) => async ({
  decisionCookieName,
  revisionHash
}) => {
  if (isCrawler() || !active) {
    // Ignore unnecessary requests
    return false;
  } // Check if revision has changed, and probably access our dynamic predecision again


  const decision = getUserDecision(decisionCookieName);

  if (decision) {
    const {
      revision
    } = decision;

    if (revisionHash === revision) {
      // Still the same revision, so we can simply accept our consent as it is still the predecision we had previously
      return "consent";
    }
  }

  try {
    const {
      predecision
    } = await promiseTimeout(request(), timeout);
    return predecision;
  } catch (e) {
    return false;
  }
};

export { preDecisionGatewayDynamicRequest };