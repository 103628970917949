import { Records } from ".";
import { calcFullSelector } from "../utils";

class Recorder {
  constructor(element) {
    this.element = void 0;
    this.records = void 0;

    this.interactionScroll = e => {
      const target = e.target;
      const selector = calcFullSelector(target, this.element);

      if (selector) {
        this.records.addScroll(selector, target.scrollTop);
      }
    };

    this.interactionClick = e => {
      const target = e.target;
      const selector = calcFullSelector(target, this.element);

      if (selector) {
        this.records.addClick(selector);
      }
    };

    this.interactionResize = () => {
      const {
        innerWidth,
        innerHeight
      } = window;
      this.records.addResize(innerWidth, innerHeight);
    };

    this.element = element;
    this.records = new Records();
  }

  restart() {
    this.records = new Records();
    this.toggle(true);
  }

  createReplay() {
    this.toggle(false);
    return this.records.createReplay();
  }

  toggle(state) {
    const {
      element: {
        addEventListener,
        removeEventListener
      },
      records
    } = this;
    records.toggle(state);

    if (state) {
      this.interactionResize();
      addEventListener("scroll", this.interactionScroll, true);
      addEventListener("click", this.interactionClick, true);
      addEventListener("touch", this.interactionClick, true);
      addEventListener("resize", this.interactionResize, true);
    } else {
      removeEventListener("scroll", this.interactionScroll, true);
      removeEventListener("click", this.interactionClick, true);
      removeEventListener("touch", this.interactionClick, true);
      removeEventListener("resize", this.interactionResize, true);
    }
  }

  getRecords() {
    return this.records;
  }

}

export { Recorder };