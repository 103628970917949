function bannerGroupsInlineStyle({
  customCss: {
    antiAdBlocker
  }
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-groups",
    style: {
      marginTop: 10,
      clear: "both"
    }
  };
}

export { bannerGroupsInlineStyle };