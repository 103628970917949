import { Fragment } from "react";
import { CookieProperty } from ".";
import { useBannerOrBlocker } from "../../../hooks";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";

const CookiePropertyList = ({
  cookie: {
    purpose,
    provider,
    providerPrivacyPolicyUrl,
    providerLegalNoticeUrl,
    legalBasis,
    ePrivacyUSA,
    isEmbeddingOnlyExternalResources,
    technicalDefinitions,
    codeDynamics
  },
  isEssentialGroup
}) => {
  const {
    i18n,
    ePrivacyUSA: ePrivacyUSAEnabled
  } = useBannerOrBlocker();
  const types = getCookieTypeLocalizationMap();
  return h(Fragment, null, !!purpose && h(CookieProperty, {
    label: i18n.purpose,
    value: purpose
  }), h(CookieProperty, {
    label: i18n.legalBasis,
    value: legalBasis === "legal-requirement" ? i18n.legalRequirement : legalBasis === "legitimate-interest" || isEssentialGroup ? i18n.legitimateInterest : i18n.consent
  }), h(CookieProperty, {
    label: i18n.provider,
    value: provider
  }), !!providerPrivacyPolicyUrl && h(CookieProperty, {
    label: i18n.providerPrivacyPolicyUrl,
    value: providerPrivacyPolicyUrl
  }), !!providerLegalNoticeUrl && h(CookieProperty, {
    label: i18n.providerLegalNoticeUrl,
    value: providerLegalNoticeUrl
  }), !!ePrivacyUSAEnabled && h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: ePrivacyUSA,
    printValueAs: "boolean"
  }), !isEmbeddingOnlyExternalResources && technicalDefinitions.map(({
    type,
    name,
    host,
    duration,
    durationUnit,
    isSessionDuration,
    purpose
  }) => h(CookieProperty, {
    key: name,
    label: i18n.technicalCookieDefinition,
    value: h("span", {
      style: {
        fontFamily: "monospace"
      }
    }, applyDynamicsToHtml(name, codeDynamics))
  }, h(CookieProperty, {
    label: i18n.type,
    value: types[type].name
  }), !!purpose && h(CookieProperty, {
    label: i18n.purpose,
    value: purpose
  }), !!host && h(CookieProperty, {
    label: i18n.host,
    value: h("span", {
      style: {
        fontFamily: "monospace"
      }
    }, host)
  }), h(CookieProperty, {
    label: i18n.duration,
    value: ["local", "indexedDb", "flash"].indexOf(type) > -1 ? i18n.noExpiration : isSessionDuration || type === "session" ? "Session" : "".concat(duration, " ").concat(i18n.durationUnit[durationUnit])
  }))));
};

function getCookieTypeLocalizationMap() {
  return {
    http: {
      name: "HTTP Cookie",
      abbr: "HTTP",
      backgroundColor: "black"
    },
    local: {
      name: "Local Storage",
      abbr: "Local",
      backgroundColor: "#b3983c"
    },
    session: {
      name: "Session Storage",
      abbr: "Session",
      backgroundColor: "#3c99b3"
    },
    flash: {
      name: "Flash Local Shared Object",
      abbr: "Flash",
      backgroundColor: "#b33c3c"
    },
    indexedDb: {
      name: "IndexedDB",
      abbr: "I-DB",
      backgroundColor: "#4ab33c"
    }
  };
}

export { CookiePropertyList, getCookieTypeLocalizationMap };