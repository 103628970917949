/**
 * Get the default decision and respect essential group and legitimate interests.
 */
function getDefaultDecision({
  groups,
  essentialGroupSlug
}, respectLegitimateInterests = true) {
  const [essentialGroupObj] = groups.filter(({
    slug
  }) => slug === essentialGroupSlug); // We need always use the current essential group

  const result = {
    [essentialGroupObj.id]: essentialGroupObj.items.map(({
      id
    }) => id)
  };

  if (respectLegitimateInterests) {
    for (const group of groups) {
      if (group === essentialGroupObj) {
        continue;
      }

      const ids = group.items.filter(({
        legalBasis
      }) => legalBasis === "legitimate-interest").map(({
        id
      }) => id);

      if (ids.length) {
        result[group.id] = ids;
      }
    }
  }

  return result;
}

export { getDefaultDecision };