import { ERouteHttpVerb } from "@devowl-wp/api";
import { trailingslashit, untrailingslashit } from "../../helpers";
import deepMerge from "deepmerge";
import qs from "querystringify";
import Cookie from "js-cookie";
import sha1 from "sha-1";

function applyQueryString(url, query, merge) {
  url.search = qs.stringify(merge ? deepMerge.all([qs.parse(url.search), ...query]) : query, true);
  return url;
}
/**
 * Build an URL for a specific scheme.
 *
 * @param param0
 */


function commonUrlBuilder({
  location,
  params = {},
  nonce = true,
  options,
  cookieValueAsParam
}) {
  const {
    href
  } = window.location;
  const apiUrl = new URL(options.restRoot, href);
  const query = qs.parse(apiUrl.search);
  const permalinkPath = query.rest_route || apiUrl.pathname; // Determine path from permalink settings
  // Find dynamic parameters from URL bindings (like /user/:id)

  const foundParams = [];
  let path = location.path.replace(/:([A-Za-z0-9-_]+)/g, (match, group) => {
    foundParams.push(group);
    return params[group];
  });
  const getParams = {}; // Find undeclared body params (which are not bind above) and add it to GET query

  for (const checkParam of Object.keys(params)) {
    if (foundParams.indexOf(checkParam) === -1) {
      getParams[checkParam] = params[checkParam]; // We do not need `encodeURIComponent` as it is supported by `querystringify` already
    }
  } // Calculate invalidator for cookie values


  if (cookieValueAsParam) {
    getParams._httpCookieInvalidate = sha1(JSON.stringify(cookieValueAsParam.map(Cookie.get)));
  } // Find parameters from passed location


  const {
    search: pathSearch,
    pathname: fixedPathName
  } = new URL(location.path, href);

  if (pathSearch) {
    const parsed = qs.parse(pathSearch);

    for (const pathSearchEntry in parsed) {
      getParams[pathSearchEntry] = parsed[pathSearchEntry];
    }

    path = fixedPathName;
  } // Force protocol from parent location


  apiUrl.protocol = window.location.protocol; // Set path depending on permalink settings

  const usePath = trailingslashit(permalinkPath) + untrailingslashit(location.namespace || options.restNamespace) + path;

  if (query.rest_route) {
    query.rest_route = usePath; // eslint-disable-line @typescript-eslint/naming-convention
  } else {
    apiUrl.pathname = usePath; // Set path
  } // Append WordPress REST nonce


  if (nonce && options.restNonce) {
    query._wpnonce = options.restNonce;
  } // Determine if non-GET verbs should always use `POST`


  applyQueryString(apiUrl, query);

  if (["wp-json/", "rest_route="].filter(s => apiUrl.toString().indexOf(s) > -1).length > 0 && location.method && location.method !== ERouteHttpVerb.GET) {
    applyQueryString(apiUrl, [{
      _method: location.method
    }], true);
  } // Build final search


  applyQueryString(apiUrl, [options.restQuery, getParams], true);
  return apiUrl.toString();
}

export { commonUrlBuilder, applyQueryString, qs };