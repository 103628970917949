import { scaleMobile } from "../..";

function bannerHeaderInlineStyleLogo({
  headerDesign: {
    logoMaxHeight,
    logoMargin,
    logoFitDim,
    logoRetinaFitDim
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}, isRetina) {
  const useFitDim = isRetina ? logoRetinaFitDim : logoFitDim;
  const dimStyle = useFitDim ? {
    width: useFitDim[0],
    height: useFitDim[1]
  } : {
    width: "auto",
    height: scaleMobile(mobile, pageRequestUuid4, +logoMaxHeight)
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-logo",
    style: { ...dimStyle,
      margin: scaleMobile(mobile, pageRequestUuid4, logoMargin).map(m => "".concat(m, "px")).join(" ") //[logoPosition === "left" ? "marginRight" : "marginLeft"]: logoPosition === "above" ? undefined : 15,
      //marginBottom: logoPosition === "above" ? 5 : undefined

    }
  };
}

export { bannerHeaderInlineStyleLogo };