import { consent, consentAll, consentSync, unblock, wrapFn } from ".";

/**
 * Create the API functions and make them available through `window.consentApi`.
 *
 * There is also a [stub](https://shorturl.at/bcgyz) implementation:
 *
 * ```js
 * ((a,b)=>{a[b]||(a[b]={},["consentSync"].forEach(c=>a[b][c]=()=>({cookie:null,consentGiven:!1,cookieOptIn:!0})),["consent","consentAll","unblock"].forEach(c=>a[b][c]=(...d)=>new Promise(e=>a.addEventListener(b,()=>{a[b][c](...d).then(e)},{once:!0}))))})(window,"consentApi");
 * ```
 */
function createApi(manager, blocker) {
  const apiConsent = (...args) => consent(manager, ...args);

  const apiConsentAll = (...args) => consentAll(manager, ...args);

  const apiConsentSync = (...args) => consentSync(manager, ...args);

  const apiConsentUnblock = (...args) => unblock(blocker, ...args);

  const wrapApis = {
    consent: apiConsent,
    consentAll: apiConsentAll,
    consentSync: apiConsentSync,
    unblock: apiConsentUnblock
  };

  const apiWrapFn = (...args) => wrapFn(wrapApis, blocker, manager, ...args);

  const api = { ...wrapApis,
    wrapFn: apiWrapFn
  };
  window.consentApi = api; // Dispatch event so e.g. stubs can react to it and forward functions

  window.dispatchEvent(new CustomEvent("consentApi"));
  return api;
}

export { createApi };