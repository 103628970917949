import { getUserDecision } from "../decision";
/**
 * Has the user already consent to the latest requested revision (hash)?
 */

const preDecisionGatewayUserConsent = async ({
  decisionCookieName,
  revisionHash
}) => {
  const decision = getUserDecision(decisionCookieName);

  if (decision === false) {
    return false;
  }

  const {
    revision
  } = decision;
  return revisionHash === revision ? "consent" : false;
};

export { preDecisionGatewayUserConsent };