/**
 * Is a passed host string valid?
 *
 * @param url
 * @see https://stackoverflow.com/a/106223/5506547
 */
function isHost(url) {
  return /^\.?(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/gm.test(url);
}

export { isHost };