import { scaleMobile } from "../..";

function bannerHeaderInlineStyle({
  layout: {
    type,
    bannerMaxWidth
  },
  design: {
    textAlign
  },
  headerDesign: {
    inheritTextAlign,
    logo,
    logoPosition,
    ...restHeaderDesign
  },
  texts: {
    headline
  },
  individualLayout,
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4,
  individualPrivacyOpen
}) {
  const useTextAlign = inheritTextAlign ? textAlign : restHeaderDesign.textAlign;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header",
    style: {
      transition: "width 500ms, max-width 500ms",
      maxWidth: scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
      margin: "auto",
      display: "flex",
      justifyContent: useTextAlign === "center" ? "center" : useTextAlign === "right" ? "flex-end" : undefined,
      textAlign: useTextAlign,
      alignItems: "center",
      position: "relative",
      flexDirection: logo && headline ? logoPosition === "left" ? "row" : logoPosition === "right" ? "row-reverse" : "column" : undefined
    }
  };
}

export { bannerHeaderInlineStyle };