import { useBanner } from "../..";
import { useCallback } from "react";
/**
 * Provide callbacks for all the available button types in our cookie banner:
 *
 * - Accept all
 * - Continue without consent (accept essentials)
 * - Save custom choices (no changes to the current selected groups and services)
 * - Change individual privacy preferences
 */

function useBannerButtonCallbacks() {
  const {
    individualPrivacyOpen,
    onSave,
    updateGroupChecked,
    updateCookieChecked,
    groups,
    essentialGroup,
    updateIndividualPrivacyOpen,
    activeAction,
    onClose
  } = useBanner();
  const buttonClickedAll = individualPrivacyOpen ? "ind_all" : "main_all";
  const buttonClickedEssentials = individualPrivacyOpen ? "ind_essential" : "main_essential";
  const buttonClickedCloseIcon = individualPrivacyOpen ? "ind_close_icon" : "main_close_icon";
  const buttonClickedCustom = individualPrivacyOpen ? "ind_custom" : "main_custom";
  const result = {
    buttonClickedAll,
    buttonClickedEssentials,
    buttonClickedCloseIcon,
    buttonClickedCustom,
    acceptAll: useCallback(() => {
      groups.forEach(g => updateGroupChecked(g.id, true));
      onSave(false, buttonClickedAll);
    }, [buttonClickedAll]),

    /**
     * This includes the complete "Essential" group + legal basis = legitimate interests.
     */
    acceptEssentials: useCallback((isCloseIcon = false) => {
      groups.forEach(({
        slug,
        id,
        items
      }) => {
        if (slug === essentialGroup) {
          updateGroupChecked(id, true);
        } else {
          for (const {
            legalBasis,
            id: cookieId
          } of items) {
            updateCookieChecked(id, cookieId, legalBasis === "legitimate-interest");
          }
        }
      });
      onSave(false, isCloseIcon ? buttonClickedCloseIcon : buttonClickedEssentials);
    }, [buttonClickedEssentials]),
    acceptIndividual: useCallback(() => onSave(false, buttonClickedCustom), [buttonClickedCustom]),
    openIndividualPrivacy: useCallback(() => {
      updateIndividualPrivacyOpen(true);
    }, [updateIndividualPrivacyOpen])
  };
  return { ...result,
    closeIcon: useCallback(() => {
      if (!activeAction) {
        // If we do not have yet a consent and it shows the banner for the first time,
        // the close icon is equivalent to "Continue without consent"
        result.acceptEssentials(true);
      } else {
        onClose();
      }
    }, [activeAction, onClose, result.acceptEssentials])
  };
}

export { useBannerButtonCallbacks };