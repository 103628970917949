import { useCallback } from "react";
import { useBannerAnimation } from "../..";
/**
 * Provides a callback which can be used to update the state of the banner context and manipulate
 * the `visible` and `animationVisible` property correctly.
 */

function useBannerToggleOverlay(overlay) {
  const useBannerAnimationCallback = useBannerAnimation();
  const toggleOverlay = useCallback(({
    layout
  }, show) => {
    const {
      animationInDuration,
      animationOutDuration
    } = layout;
    const {
      useAnimationIn,
      useAnimationOut
    } = useBannerAnimationCallback(layout);
    const defaultIfNoAnimation = 0; // Avoid "hard" overlay

    const useDuration = show ? useAnimationIn === "none" ? defaultIfNoAnimation : animationInDuration : useAnimationOut === "none" ? defaultIfNoAnimation : animationOutDuration; // Toggle SSR-rendered overlay

    useDuration > 0 && (overlay.style.transition = "background ".concat(useDuration, "ms"));
    overlay.style.background = "transparent";

    if (show) {
      // We need a small delay to avoid flickering of animation out effect in customizer
      setTimeout(() => {
        overlay.style.display = "block";
      }, 0);
      setTimeout(() => {
        const bg = overlay.getAttribute("data-bg");
        overlay.style.background = bg ? bg.split(":")[1].trim().replace(";", "") : "transparent";
      }, 100);
    } else {
      setTimeout(() => {
        overlay.style.display = "none";
      }, useDuration);
    }

    const animationVisible = {
      animationVisible: show
    };
    return show ? {
      visible: true,
      ...animationVisible
    } : animationVisible;
  }, [useBannerAnimationCallback]);
  return {
    overlay,
    toggleOverlay
  };
}

export { useBannerToggleOverlay };