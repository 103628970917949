import { matchingSelector } from "@devowl-wp/resolve-none-computed-style";
/**
 * Port of `jQuery.parents()`.
 *
 * @param selector Use `undefined` to get all parents
 */

function parents(element, selector, limit = 0) {
  const elements = [];
  let elem = element.parentElement;

  while (elem !== null) {
    if (selector === undefined || elem.nodeType === Node.ELEMENT_NODE && matchingSelector(elem, selector)) {
      elements.push(elem);
    }

    elem = elem.parentElement;

    if (limit !== 0 && elements.length >= limit) {
      break;
    }
  }

  return elements;
}

export { parents };