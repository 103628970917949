import { BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "..";

const calculateNewVal = (val, scalePercent) => {
  let newVal = val;
  newVal = val / 100 * scalePercent;
  newVal = newVal < 1 ? 1 : newVal;
  return newVal;
};
/**
 * Calculate the new value for an inline style value and respect the scaling options.
 */


function scaleMobile({
  enabled,
  scalePercent,
  scalePercentVertical
}, useWidthOfThisContainerId, original, replaceWith, additionalVerticalScaling = false) {
  if (process.env.PLUGIN_CTX === "pro") {
    var _document$getElementB;

    /* onlypro:start */
    const useScalePercentVertical = +scalePercent + +scalePercentVertical;
    const {
      innerWidth
    } = window;
    const width = useWidthOfThisContainerId ? ((_document$getElementB = document.getElementById(useWidthOfThisContainerId)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.clientWidth) || innerWidth : innerWidth;
    const doChange = enabled && width <= BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER;

    if (doChange) {
      if (replaceWith === undefined) {
        // MobX observable compatibility
        const useOriginal = typeof original.toJS === "function" ? original.toJS() : original; // Calculate new sizes depending on type

        if (typeof useOriginal === "number" && useOriginal > 0) {
          return calculateNewVal(useOriginal, additionalVerticalScaling ? useScalePercentVertical : scalePercent);
        } // Arrays (e.g. padding)


        if (Array.isArray(useOriginal) && useOriginal.map(v => typeof v === "number" && v > 0).filter(Boolean).length === useOriginal.length) {
          return useOriginal.map((v, i) => calculateNewVal(v, [0, 2].indexOf(i) > -1 && additionalVerticalScaling ? useScalePercentVertical : scalePercent));
        }

        return original;
      }

      return replaceWith;
    }
    /* onlypro:end */

  }

  return original;
}

export { scaleMobile };