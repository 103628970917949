import { scaleMobile } from "../../scale";

function commonInlineStyleFooterLink({
  footerDesign: {
    fontSize,
    fontColor,
    hoverFontColor,
    fontInheritFamily,
    fontFamily
  },
  design: {
    linkTextDecoration
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  // only used for banner
  pageRequestUuid4
}, isHover = false) {
  const style = {
    textDecoration: linkTextDecoration,
    fontSize: pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +fontSize) : +fontSize,
    color: isHover ? hoverFontColor : fontColor,
    fontFamily: fontInheritFamily ? undefined : fontFamily,
    padding: "0 5px"
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-link",
    style
  };
}

export { commonInlineStyleFooterLink };