import _extends from "@babel/runtime/helpers/extends";
import { useState } from "react";
import { commonInlineStyleFooterLink, useBannerOrBlocker } from "../../..";

const FooterLink = ({
  children,
  ...anchorRest
}) => {
  const bannerOrBlocker = useBannerOrBlocker();
  const [isHover, setIsHover] = useState(false);
  return h("a", _extends({
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false)
  }, commonInlineStyleFooterLink(bannerOrBlocker, isHover), anchorRest), children);
};

export { FooterLink };