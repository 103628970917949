import { getUserDecision } from "../decision";
import { isDoNotTrack } from "../utils";

/**
 * When the user has "Do not track" enabled and is allowed to use, let's automatically
 * save the dialog without any action.
 */
const preDecisionGatewayIsDoNotTrack = (active = true) => async ({
  decisionCookieName,
  groups,
  essentialGroupSlug
}) => {
  const [essentialGroupObj] = groups.filter(({
    slug
  }) => slug === essentialGroupSlug);

  if (getUserDecision(decisionCookieName) !== false || !active) {
    return false;
  } // As soon we have a legitimate interest, we need to show the cookie banner


  for (const group of groups) {
    if (group !== essentialGroupObj) {
      for (const {
        legalBasis
      } of group.items) {
        if (legalBasis === "legitimate-interest") {
          return false;
        }
      }
    }
  }

  return isDoNotTrack() ? "dnt" : false;
};

export { preDecisionGatewayIsDoNotTrack };