function commonInlineStyleGroupsCookie({
  customCss: {
    antiAdBlocker
  }
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-cookie",
    style: {
      marginTop: 10
    }
  };
}

export { commonInlineStyleGroupsCookie };