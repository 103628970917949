import { getUserDecision, OPT_IN_ALL_EVENT } from "@devowl-wp/cookie-consent-web-client";
import { useEffect } from "react";

function useBannerConsentListener(setContextValue, userConsentCookieName) {
  useEffect(() => {
    const listener = () => {
      const consent = getUserDecision(userConsentCookieName);
      consent && setContextValue(state => ({ ...state,
        consent: {
          groups: consent.consent
        }
      }));
    };

    document.addEventListener(OPT_IN_ALL_EVENT, listener);
    return () => {
      document.removeEventListener(OPT_IN_ALL_EVENT, listener);
    };
  }, []);
}

export { useBannerConsentListener };