import { scaleMobile } from "../..";

function bannerGroupsInlineStyleTitle({
  group: {
    headlineFontSize,
    headlineFontColor,
    headlineFontWeight
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group",
    style: {
      color: headlineFontColor,
      fontSize: scaleMobile(mobile, pageRequestUuid4, headlineFontSize),
      fontWeight: headlineFontWeight,
      textAlign: "left"
    }
  };
}

export { bannerGroupsInlineStyleTitle };