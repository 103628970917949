import { useCallback } from "react";
import { CommonCheckbox, CommonLinkMore, useBanner, bannerGroupsInlineStyleGroup, BannerGroupSectionTitle, BannerCookie } from "../../..";

const BannerGroup = ({
  group
}) => {
  var _consent$groups$id;

  const banner = useBanner();
  const {
    id,
    slug,
    name,
    description,
    items
  } = group;
  const {
    previewCheckboxActiveState,
    consent,
    activeAction,
    essentialGroup,
    individualTexts: {
      showMore,
      hideMore
    }
  } = banner;
  const isEssentialGroup = essentialGroup === slug;
  const isDisabled = isEssentialGroup || activeAction === "history";
  const isChecked = previewCheckboxActiveState || isEssentialGroup || !!consent.groups[id];
  const isPartial = !isEssentialGroup && JSON.stringify(items.map(({
    id
  }) => id).sort()) !== JSON.stringify(((_consent$groups$id = consent.groups[id]) === null || _consent$groups$id === void 0 ? void 0 : _consent$groups$id.sort()) || []);
  const handleCheckbox = useCallback(() => !isDisabled && banner.updateGroupChecked(id, !isChecked), [isDisabled, banner, id, isChecked]);
  return h("div", bannerGroupsInlineStyleGroup(banner), h(BannerGroupSectionTitle, {
    title: h(CommonCheckbox, {
      onClick: handleCheckbox,
      isPartial: isPartial,
      isChecked: isChecked,
      isDisabled: isDisabled
    }, name, " (", items.length, ")")
  }, description, !!items && h(CommonLinkMore, {
    showMore: showMore,
    hideMore: hideMore
  }, items.map(cookie => h(BannerCookie, {
    key: cookie.id,
    group: group,
    cookie: cookie
  })))));
};

export { BannerGroup };