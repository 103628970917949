import { useBannerOrBlocker } from ".";

function useLegalLinks() {
  const {
    legal: {
      privacyPolicy,
      privacyPolicyExternalUrl,
      privacyPolicyIsExternalUrl,
      privacyPolicyLabel,
      imprint,
      imprintExternalUrl,
      imprintIsExternalUrl,
      imprintLabel
    },
    pageByIdUrl
  } = useBannerOrBlocker();
  const pageIdToPermalink = useBannerOrBlocker().pageIdToPermalink || {};
  let linkPrivacyPolicy = privacyPolicy > 0 ? pageIdToPermalink[privacyPolicy] || "".concat(pageByIdUrl, "=").concat(privacyPolicy) : undefined;
  let linkImprint = imprint > 0 ? pageIdToPermalink[imprint] || "".concat(pageByIdUrl, "=").concat(imprint) : undefined;

  if (privacyPolicyIsExternalUrl) {
    linkPrivacyPolicy = privacyPolicyExternalUrl;
  }

  if (imprintIsExternalUrl) {
    linkImprint = imprintExternalUrl;
  }

  return {
    linkPrivacyPolicy: privacyPolicyLabel && linkPrivacyPolicy ? {
      url: linkPrivacyPolicy,
      label: privacyPolicyLabel
    } : false,
    linkImprint: imprintLabel && linkImprint ? {
      url: linkImprint,
      label: imprintLabel
    } : false
  };
}

export { useLegalLinks };