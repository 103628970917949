import { scaleMobile } from "../..";

function bannerGroupsInlineStyleDescription({
  design: {
    fontWeight
  },
  group: {
    descriptionFontColor,
    descriptionFontSize
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group-description",
    style: {
      color: descriptionFontColor,
      fontSize: scaleMobile(mobile, pageRequestUuid4, +descriptionFontSize),
      fontWeight,
      marginTop: 5
    }
  };
}

export { bannerGroupsInlineStyleDescription };