import { getOptionsFromWindow } from "./getOptionsFromWindow";
/**
 * Get the version of Real Cookie Banner as integer.
 */

function getCmpVersion(type) {
  const split = getOptionsFromWindow().version.split(".");
  return +(type === "major" ? split[0] : split.map(i => +i < 10 ? "0".concat(i) : i).join("")); // 1.11.1 => 1011000; 2.5.0 => 2005001;
}

export { getCmpVersion };