const PATTERN_NULL = /^null | null$|^[^(]* null /i;
const PATTERN_UNDEFINED = /^undefined | undefined$|^[^(]* undefined /i;
/**
 * TypeScript port of https://github.com/facebook/idx/blob/bbd936928263a261b6feffd237e1e9ed93764fac/packages/idx/src/idx.js#L14-L57.
 */

function idx(input, accessor) {
  try {
    return accessor(input);
  } catch (error) {
    if (error instanceof TypeError) {
      const errorString = error.toString();

      if (PATTERN_NULL.test(errorString)) {
        return null;
      } else if (PATTERN_UNDEFINED.test(errorString)) {
        return undefined;
      }
    }

    throw error;
  }
}

export { idx };