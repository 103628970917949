import { supportsCookies } from "../utils";
/**
 * Does the current browser support cookies?
 */

const preDecisionGatewaySupportsCookies = async ({
  supportsCookiesName
}) => {
  return supportsCookies(supportsCookiesName) ? false : "essentials";
};

export { preDecisionGatewaySupportsCookies };