import { scaleMobile } from "../..";

function bannerHeaderInlineStyleSeparator({
  layout: {
    type
  },
  design,
  headerDesign: {
    borderWidth,
    borderColor
  },
  customCss: {
    antiAdBlocker
  },
  mobile,
  pageRequestUuid4
}) {
  const useDesignBorderWidth = scaleMobile(mobile, pageRequestUuid4, design.borderWidth);
  const style = {
    height: scaleMobile(mobile, pageRequestUuid4, +borderWidth),
    background: borderColor,
    position: "sticky",
    top: "var(--rendered-height-header)",
    zIndex: 9
  };

  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog" && useDesignBorderWidth > 0) {
    style.borderLeft = "".concat(useDesignBorderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header-separator",
    style
  };
}

export { bannerHeaderInlineStyleSeparator };