/**
 * Do not show cookie banner for a certain page, e.g. the login page.
 */
const preDecisionGatewayHasPageClass = classNames => async () => {
  const {
    className
  } = document.body;
  return classNames.filter(c => className.indexOf(c) > -1).length > 0 ? "consent" : false;
};

export { preDecisionGatewayHasPageClass };