import { getUserDecision } from "../decision";
import { isCrawler } from "../utils";
/**
 * Is the current request from a spider / crawler?
 */

const preDecisionGatewayIsCrawler = resultIfBot => async ({
  decisionCookieName
}) => {
  if (getUserDecision(decisionCookieName) !== false || !resultIfBot) {
    return false;
  }

  return isCrawler() ? resultIfBot : false;
};

export { preDecisionGatewayIsCrawler };