var ERouteHttpVerb;

(function (ERouteHttpVerb) {
  ERouteHttpVerb["GET"] = "GET";
  ERouteHttpVerb["POST"] = "POST";
  ERouteHttpVerb["PUT"] = "PUT";
  ERouteHttpVerb["PATCH"] = "PATCH";
  ERouteHttpVerb["DELETE"] = "DELETE";
})(ERouteHttpVerb || (ERouteHttpVerb = {}));

export { ERouteHttpVerb };