/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is only loaded if the visitor needs to accept cookies.
 */
import { render } from "react-dom";
import { WebsiteBanner } from "./components";
import { getOtherOptionsFromWindow, ensureBodyElement } from "./utils";
import { listenPresetOptIn, deleteCookiesServer, getCookieConsentManager } from "./others";
import { tcfCmpApi } from "./tcf";
import { getWebpackPublicPath } from "@devowl-wp/utils";
import { idx } from "@devowl-wp/cookie-consent-web-client";
import { OPT_OUT_ALL_EVENT, ready, printConsentUuidsToHTMLElement, createApi } from "@devowl-wp/cookie-consent-web-client";
import { getSsrPoweredByLink } from "@devowl-wp/react-cookie-banner";
__webpack_public_path__ = getWebpackPublicPath(process.env.slug);
const {
  blocker,
  pageRequestUuid4
} = getOtherOptionsFromWindow();
listenPresetOptIn();
tcfCmpApi();
ready(() => {
  const poweredLink = getSsrPoweredByLink("".concat(pageRequestUuid4, "-powered-by"));
  const element = ensureBodyElement(document.getElementById(pageRequestUuid4));
  printConsentUuidsToHTMLElement(".rcb-consent-history-uuids", getCookieConsentManager());
  document.addEventListener(OPT_OUT_ALL_EVENT, async ({
    detail: {
      deleteHttpCookies
    }
  }) => {
    // Handle cookie deletion which needs to be done server-side (e. g. HTTP cookies)
    deleteHttpCookies.length && setTimeout(() => deleteCookiesServer(deleteHttpCookies), 0);
  });

  if (element) {
    render(h(WebsiteBanner, {
      poweredLink: poweredLink
    }), element);
  }
}, "interactive"); // Expose public API

const {
  wrapFn
} = createApi(getCookieConsentManager(), blocker); // Wrap some functions

const fnGetWindow = () => window;

wrapFn({
  object: () => idx(window, window => window.mkdf.modules.destinationMaps.mkdfGoogleMaps),
  key: "getDirectoryItemsAddresses"
}, "functionBody");
wrapFn([// [Plugin Comp] https://wordpress.org/plugins/bold-page-builder/
{
  object: fnGetWindow,
  key: "bt_bb_gmap_init_new"
}, {
  object: fnGetWindow,
  key: "bt_bb_gmap_init_static_new"
}], ["unblock", "google.com/maps"]);