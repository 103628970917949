import { useCallback } from "react";
import { useBanner, Cookie } from "../../..";

const BannerCookie = ({
  group,
  cookie
}) => {
  var _consent$groups;

  const {
    id
  } = cookie;
  const banner = useBanner();
  const {
    consent,
    activeAction,
    essentialGroup
  } = banner;
  const isEssentialGroup = essentialGroup === group.slug;
  const isDisabled = isEssentialGroup || activeAction === "history";
  const isChecked = isEssentialGroup || (((_consent$groups = consent.groups) === null || _consent$groups === void 0 ? void 0 : _consent$groups[group.id]) || []).some(c => c === id);
  const handleCheckbox = useCallback(() => !isDisabled && banner.updateCookieChecked(group.id, id, !isChecked), [isDisabled, banner, group, id, isChecked]);
  return h(Cookie, {
    cookie: cookie,
    propertyListProps: {
      isEssentialGroup
    },
    checked: isChecked,
    disabled: isDisabled,
    onToggleCheck: handleCheckbox
  });
};

export { BannerCookie };