import _extends from "@babel/runtime/helpers/extends";
import { useBanner, bannerInlineStyleHistorySelect, bannerInlineStyleHistorySelectOption } from "../../..";
import { useMemo } from "react";
import { Fragment, useState, useEffect } from "react";
const SELECT_NONE = -1;

const BannerHistorySelect = () => {
  const banner = useBanner();
  const [selectedId, setSelectedId] = useState(SELECT_NONE);
  const {
    activeAction,
    history,
    selectHistoryEntry,
    i18n: {
      historyLabel,
      historySelectNone
    }
  } = banner;
  const selectedHistoryItem = useMemo(() => {
    var _history$filter;

    return history === null || history === void 0 ? void 0 : (_history$filter = history.filter(({
      id
    }) => id === selectedId)) === null || _history$filter === void 0 ? void 0 : _history$filter[0];
  }, [selectedId]); // Reset select after exit history dialog

  useEffect(() => {
    if (!activeAction) {
      setSelectedId(SELECT_NONE);
    } else if (activeAction === "history" && history !== null && history !== void 0 && history.length) {
      setSelectedId(history[0].id);
    }
  }, [activeAction, history]); // History can only contain my data, so we can pick the first entry for the history

  const uuid = selectedHistoryItem === null || selectedHistoryItem === void 0 ? void 0 : selectedHistoryItem.uuid;
  const optionStyle = bannerInlineStyleHistorySelectOption(banner);
  return h(Fragment, null, historyLabel, "\xA0", h("select", _extends({}, bannerInlineStyleHistorySelect(banner), {
    disabled: !(history !== null && history !== void 0 && history.length),
    value: selectedId,
    onChange: e => {
      const value = +e.target.value;
      history.forEach(({
        id,
        decision,
        groups,
        tcf
      }) => {
        if (id === value) {
          setSelectedId(id);
          selectHistoryEntry(groups, decision, tcf);
        }
      });
    }
  }), (history === null || history === void 0 ? void 0 : history.length) > 0 ? history.map(({
    id,
    isDoNotTrack,
    isUnblock,
    isForwarded,
    created
  }) => h("option", _extends({
    key: id,
    value: id
  }, optionStyle), new Date(created).toLocaleString(document.documentElement.lang), isDoNotTrack ? " (Do Not Track)" : "", isUnblock ? " (Content Blocker)" : "", isForwarded ? " (Consent Forwarding)" : "")) : h("option", _extends({
    value: SELECT_NONE
  }, optionStyle), historySelectNone)), !!uuid && h("div", {
    style: {
      opacity: 0.5,
      marginTop: 5
    }
  }, "UUID: ", uuid));
};

export { BannerHistorySelect };